import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Foot  from '../components/Foot'
import digital from '../assets/images/digital-market.png'
import ux from '../assets/images/ux.webp'
import ticker from '../assets/images/ticker.png'
import smm from '../assets/images/socialMediaMarketing.png'
import bull from '../assets/images/istockphoto-840201636-1024x1024.jpg'
import content from '../assets/images/content.png'
import pic02 from '../assets/images/pic05.jpg'

const Industries = props => (
  <Layout >
  <Helmet
  htmlAttributes={{ lang: 'en' }}
  title="Industries - Bulls i Marketing"
  meta={[
    { name: 'description', content: 'Need to look brilliant? Have a better-built website with LYFE Marketing.' },
    { name: 'keywords', content: 'Bulls i Marketing, Web Dev, Web Developmennt, User Experience, responsive, UX, User Friendly' },
  ]}
></Helmet>

    <div id="main" className="wrapper style5"
    style={{ backgroundImage: `url(${pic02})`,
    backgroundAttachment: "fixed",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    padding: 0
  }}
        

    >
        <div className="wrapper" style={{
        backgroundColor: "rgba(255, 255, 255, 0.78)",
        backdropFilter: "blur(52px)",
        padding: "5rem 1rem 3rem 1rem"
      }}>
        <div className="container"
                
        >
        <article  >
            <header className="major">
                <h2>Why Invest in Digital Marketing?</h2>
            </header>
    
            <section>
                <section>
                <p>
                The way the world has changed towards digitalization, Digital Marketing is helping businesses grow and bring in remarkable revenue. 
                </p>
                    <p>
                    <span className="image left">
                        <img src={digital} alt="" />
                    </span>
                    With a Compound Annual Growth Rate of 17.6% from now until 2025, the digital marketing aspect is getting attract as the whole world is turning its dependency on tech1.
                    </p>

                    <p>Digital marketing reaches people no matter where they are and reaches 3.96 billion people out of 4.57 billion global internet users on social media where they spend their time and money. </p>
                    <p>3.91 billion of 3.96 active social media users are using their smartphone devices. This is the reason mobile advertising has increased manifolds; Q2 2020 by 71% globally over Q2 2019 and 8% since Q1 2020. </p>
                    <p>
                    The perks of digital marketing include highly customized, one-to-one, and simpler evaluation. <br />
    On average, businesses using Google ads are making 2x revenue of what’s spent on ads. 
    PPC ads can promote brand awareness by up to 46% and 64% of the people shopping online will click on a Google when scrolling. The oldest method is email marketing, but it has a proven average ROI of 122% which is four times any other digital marketing strategy.

                    </p>
                    <p>
                    The digital revolution started long before the pandemic. Internet dependency increased by the migration from cell phones to smartphones, and the escalation in social media usage. In the present time, COVID-19 turned our world upside down, but it also brought the need for innovation and technology than it was ever before. Trends in customer behavior and the advancement in technology make the companies adapt and rely on these resources more and more. Businesses shifted from face-to-face to online systems and adapt to the situation and design their strategies with the speed of light. Physical offices vanished in a blink of an eye. 

                    </p>
                    <p>
                    2020’s events made businesses think critically to not only survive but also to stay in service and to the point. Businesses that were ahead of the competitors pre-pandemic and utilized digital marketing to better know the ways people use to communicate, shop online, and stream media. These were the businesses that not only survived but thrive multifold when the global pandemic hit. In fact, according to Deloitte’s report, digital sales outdid during the start of the global pandemic and increased by 18% in Q1 2020 compared to Q1 2019. Internet traffic also went up by 13% in Q1 2020 compared to Q1 2019. The share of this traffic coming from social media was 8% in Q1 2020 which was 6% in Q1 2019.  
                    </p>
                    <p>
                    COVID-19 made the inevitable digitization speed up. In this situation, businesses need to invest more and more in digital marketing as we will be living with it for decades. There are other reasons to adopt digital marketing; it targets people on a platform where they spend most of their time and money, irrespective of their location, it helps target specific audiences having common interests, it is easier to calculate costs and revenues as well as tracking the campaigns, and it cuts costs to a little than offline marketing with higher Return on Investment (ROI).
                    </p>
                    <p>
                    Businesses, with an investment into extensive digital marketing campaigns, can anticipate greater ROI and brand awareness. From now until 2025, with a CAGR of 17.6%, the importance and adoption of digital marketing is becoming inevitable. Moreover, according to Accenture, B2B eCommerce revenues have more or less doubled, accounting for almost half of all B2B revenues. Utilizing digital marketing forms such as content marketing, social media marketing, native ads. SMS/email marketing, push notifications, ticker tagging, influencer marketing, and retargeting audiences, businesses can flourish by investing time and money in an all-in-one digital strategy. 
                    </p>
                    <hr />
                </section>


                <section>
                    <h2>Content Marketing</h2>
                    <p>
                    <span className="image right">
                        <img src={content} alt="" />
                    </span>
                    In digital marketing, we always emphasize content marketing, but what is it first? Content marketing is called so because it involves creating, distributing, and publishing the most relevant content in a more consistent and targeted way. This is a long-term strategy to drive sales by delivering consistently personalized high-quality content to stay in your audience’s heart. Content marketing is all about storytelling, attracting, and retaining loyal customers by spreading brand awareness. Content marketing methods can include images, GIFS, videos, infographics, and even tweets, newsletters, and blog posts.  
                
                    </p>
                    <p>AThe survey about “the most effective digital marketing techniques” in 2018 revealed that content marketing is voted as the most effective technique in digital marketing. Big Data and AI tied at number 2 with 14% respondents only.                </p>
                    
                    <p>
                    Moreover, later in 2019, another worldwide survey showed that over 90% of the companies using digital marketing embedded content marketing to their overall digital marketing strategy. Another aspect was also revealed by the same study that almost half of the participants spent less than $10,000 on content marketing. This shows that how much cost-effective content marketing is as compared to other methods of digital and physical advertising. Between 30% to 40% of all the B2B and B2C, in North America only, planned to increase their content marketing in 2020 with documented content marketing strategies.
                    </p>
                    <p>
                    60% of the marketing professionals questioned in another survey in May 2019 and they believed that cast studies and research analysis were most-liked and trusted by their targeted audiences, while the rest of the 34% has similar thoughts about photos and infographics. Nonetheless, these pieces of content marketing are way beyond being the most engaging and popular. 
                    </p>
                    <p>
                    The consumption of visuals has been the most organic, widespread, and engaging format out of all content marketing forms. In 2019, photos and videos were found to be the most effective advertising methods across all social media platforms such as Instagram, Facebook, and YouTube by digital marketing professionals. 
                    </p>
                    <p>
                    Numbers are facts. Having over a billion users, YouTube is the second most popular search engine among internet users very next to Google. Just on YouTube, 1.57 billion users watch around 5 billion videos each day, but this includes a fraction of the entire internet base. Over 500 million hours of videos are watched by users daily throughout the entire internet. 
                    </p>
                    <p>
                    But these facts just show how common video consumption is all over the internet. Digging into statistics show that how video ads can lift engagement and sales, it is for sure that how pivotal videos are for content marketing. Recent studies disclosed that 85% of consumers are expected to purchase while 46% of them act after watching a video. In fact, if videos are combined with full-page ads, you can boost engagement by 22%. Furthermore, about 64% of the online consumers are influenced to purchase a product by watching a Facebook video and 80% of the consumers will remember a video ad they watched on the internet in the past 30 days. As a result, eCommerce sellers can boost product sales by 144% using video ads. 
                    </p>
                    <p>
                    The statistics shows that consumers prefer video ads over just simple texts and are likely to retain more information. Videos better explain the product information and promote sales as they are more eye-catching and appealing resulting in higher ROI. 
                    </p>

                    <hr />
                </section>

                <section>
                    <h2>Social Media Marketing</h2>
                    <p>One of the many best places to implement content marketing strategies is by using social media. As per Smart Insights, attracting audiences on social media the most popular method other than watching videos. Using social media marketing into content marketing brings more reach and in turn, allows you to save your ad budget. With huge audiences, creating catchy and connecting videos exclusively for Facebook works on one of the principles of content marketing.                 </p>
                    <p>
                    <span className="image left">
                        <img src={smm} alt="" />
                    </span>
                    What do the numbers tell about social media marketing and why is it important to incorporate it in your digital marketing strategy? Digital marketing provides vast exposure as compared to offline or physical marketing strategies. 4.57 billion people are regular internet users on this globe and 3.96 billion of them are active on social media platforms, this is where they spend their time and money irrespective of their location and distance from you. 
                    </p>
                    <p>
                    Social media takes 33% of the time each user of the internet spends online. Companies, from smaller to the top ones, are realizing the benefits and importance of social media advertising. But what exactly are the major benefits? According to a survey in January 2020, 86% of the global marketers said that their exposure increased, 78% said increased online traffic, 67% said generated leads, 60% said developed loyal customer base, 59% improved sales. 35% of them also said that they either strongly agree or agree with the benefit they can authoritatively calculate their social media marketing ROI. 
                    </p>
                    <p>
                    Social media marketing is strategized and is highly controlled as all of the platforms are not equally swarmed by people. Facebook is considered the most powerful of them for both B2B and B2C using mobile ads as the best way to reach people irrespective of their location. Total revenue spent on advertising is expected to reach $25.56 billion by next year. With a penetration rate of 63% as of February 2020, Facebook is the largest audience base with over 2.7 billion monthly and 1.79 billion active daily users. In the US alone, Facebook had an approximate 80% market share in social media marketing as of 2019. 
                    </p>
                    <p>
                        You will come to know by digging deeper into the numbers that the most important and effective method for reaching these Facebook users is mobile media advertising. 3.91 billion of the 3.96 billion active social media users are active on their mobile device, having 98.3% active Facebook users. Revenue spent on mobile advertising in Q2 2020 increased by 71% compared to Q2 2019, and it was increased by 8% since Q1 2020.  
                    </p>
                    <p>
                    The average social media ad expense per user visiting on mobile and desktop is also forecasted to further divide within the next 5 years. The average expense is predicted to be $111.82 on mobile users vs $21.53 on the desktop user. These average costs are expected to be $ 154.03 and $22.6814 respectively. 
                    </p>
                    <p>
                    Other social media platforms such as YouTube are also effective as it is a great source to publish videos. Making users consume videos on YouTube is the most engaging form of content marketing considering its user base and penetration rate. Currently, YouTube is at the 2 number, just behind Facebook, having 2 billion users with a penetration rate of 61%. 
                    </p>
                    <hr />
                </section>           



                <section>
                    <h2>Native Ads</h2>
                    <p>Native advertisement technique is highly unpredicted, but it can be the key to the lock of your versatile digital marketing strategy. Native ads are paid ads that match the feel, color scheme, and function of the platform you are placing them on. These are highly converting because they don’t look like conventional ads. They are often placed in your social media feeds or as recommended content on a webpage. It is proven above that video ads are engaging, and much more preferred but it has a certain drawback. In a survey in July 2019, it was concluded by a considerable margin that internet users in the US found video ads with and without a sound the most annoying types of digital ads. </p>
                    <p>Native ads are good to be included in your digital marketing strategy because they help target internet users who have either opted out or don’t trust online ads. As a matter of fact, 80% of North American adults have some kind of ad-blocking applications installed. Thus, publishing native ads that will resonate with a consumer’s feed and don’t feel annoying is the best way to interact with them, more than other ads if compared. Stating a fact, users spend almost nearly the same time on reading editorial content (2 seconds) and native ads (1 second). Additionally, a study by the digital marketing company Outbrain shows that 70% of people prefer knowing a product by reading content than other traditional ads, 53% view native ads more than banner ads, native ads boost purchase intent by 18% with a click-through rate of 40 times higher than that of classic ads. </p>
                    <p>Companies are accepting native ads now. Since 2016, native ad spending has expanded from $16.68 billion to $52.75 billion in the US alone. Three out of every four digital marketing companies offer native advertising on their website. $1% of brands are using native ads in their extensive digital marketing efforts, and 90% of the businesses are either planning to or have started their native advertisement. </p>
                    <p>While PPC ads increase brand awareness by 46%, they have shown the potential to have a strong ROI. Businesses are making $2 on an average in revenue for spending $1 on Google Ads. </p>
                    <hr />
                </section>

                <section>
                    <h2>Email Marketing</h2>
                    <p>Email marketing is the oldest yet one of the most effective methods of digital marketing methods that are being used for digital marketing.  Emails for the promotion of products or services have been used since the start of the internet. It can also be used to reach out to potential new as well as current consumers for developing or strengthening relations respectively. This form of digital marketing can be customized from messages with infographics to individualized content. </p>
                    <p>As social media or content marketing has taken control of the digital marketing methods, but email marketing is still very much popular among growing companies. Digging number will reveal that 90% of marketers use email marketing, 81% of the small businesses use it as their primary acquisition channel, and 80% rely on this for customer retention as this method is most like by the consumers around the globe. For a figurative idea, 51% of the US consumers chose emails as the preferred method to be contacted by brands, 73% of them are millennials. 59% of the US consumers also declared that email marketing impacts their minds to make purchase decisions. </p>
                    <p>The key aspects of email marketing to consider while launching an email marketing campaign are that your emails should be individualized, interactive, and warm welcoming. The open rate of the welcome email is far more with 82% than an average email with 21%. Personalized emails have a 50% higher click-through rate then irrelevant or impersonalized emails. On the other hand, emails with interactive content have an open rate of as much as 300%. </p>
                    <p>Email marketing is proven to be a conversion-rich and effective method of digital marketing with an average ROI of 122% which 4x of any other form of digital marketing. However, according to Constant Contact, an email marketing company, a company is expected to earn an average of $38 for spending every $1. Another digital marketing survey revealed that email marketing brings 25% of the overall revenue of the companies who participated. In fact, the most shocking revenue driver is segmented email campaigns which can drive up to a 760% revenue increase. </p>
                    <hr />
                </section>

                <div className="box alt">
                    <div className="row gtr-50 gtr-uniform aln-center">
                        <div className="col-10-small">
                            <span className="image fit">
                            <img src={bull} alt="" style={{maxHeight:"420px"}} />
                            </span>
                        </div>
                    </div>
                </div>

                <section>
                    <h2>SMS Marketing</h2>
                    <p>Another factor that plays an important role in the success of email marketing is because more and more people have started using their phones as email devices these days. As of 2018, 1/3 of the global email market shares were held by Apple’s iPhones and iPads. But do you know how many people on this planet have phones but don’t use email? Research shows that 50% of mobile device owners don’t use a smartphone. As of 2019, around 5 billion out of 7.9 billion people holding mobile subscriptions worldwide can send and receive SMS messages. SMS service is such basic that regardless of what type of phone someone has, SMS reaches everyone. In fact, 23 billion text messages, or 270,000 per second, are sent daily on average. So, SMS marketing is even more effective to reach audiences on their mobile phones. </p>
                    <p>
                    SMS marketing, also known as text message marketing, is a simple tactic that involves sending out coupon codes, gift cards, special discounts, alerts, promotions, freebies, and highly targeted “opt-in” texts, which are permission base. 
                    </p>
                    <p>
                    The numbers of SMS marketing’s success are astonishing. But do you know why is it the most effective mean of digital marketing? People are always on their phones and check messages more than any other delivered content form. Mobile users in the US check their phones 47 times a day, which increased to 96 times in 2019. 48.7 million people are predicted to choose business SMS opt-ins, while 59% say that an SMS marketing text influenced them to visit the store physically or online. 
                    </p>
                    <p>The open rate for SMS texts is 98% with each user spending roughly less than 3 minutes. Moreover, SMSs also have a 209% greater response rate than email, phone, and Facebook marketing methods and SMS delivered coupons are 10 times redeemed more than any other type of coupons. </p>
                    <hr />
                </section>
                <section>
                    <h2>Push Notification</h2>
                    <div>
                        
                        <p>Push notification marketing is a form where companies update their consumers about their products, services, and promotions over their phone screen via notifications on a variety of devices. As low as 52% of smartphone users have enabled push notifications on their devices. By push notification marketing, businesses can welcome the user to their business, direct them to social media channels, engage them with promotional or interactive content, and build a trust base. </p>
                    </div>
                    <div>
                    
                        <p>Push notification is another growing form of digital marketing. This method is targeted to smartphone users only – although 3 billion people as of today are not using a smartphone and cannot be marketed using this method. This number of cellular phone users is projected to increase by thousands each year. </p>
                    </div>
                    <div>
                    
                        <p>Knowing that push notifications are a standalone type of tactic, but they can be embedded into your comprehensive marketing strategy to connect with customers in a more personalized way. As of 2019, the average smartphone users receive 46 push notifications per day with a 53.3% opt-in rate across all devices. Android users are automatically enrolled in an opt-in delivered to them with 91.1% while iOS users have to willingly opt-in with a 43.9% opt-in rate. </p>
                    </div>
                    <hr />
                </section>
                <section>
                    <h2>Ticker Tagging</h2>
                    <span className="image left">
                        <img src={ticker} alt="" />
                    </span>
                    <p>Ticker tagging is an engrossing form of digital marketing tailored towards companies that are publicly traded. If a company wants to create buzz and spread awareness about them in front of the major shareholders of the same industry or it can be their competitors. The most effective way to do is by targeting these competitors or company fellows via their stock tickers, especially when they release news update. To know more, if a company is less known as an energy company, they would tag their peers through company names and tickers, for example, Exxon Mobil (NYSE: XOM), Valero (NYSE: VLO), and Chevron (NYSE: CVX). It can be any company such as a pharmaceuticals or biotech company, such as Pfizer (NYSE: PFE), Eli Lilly (NYSE: LLY), or Gilead Sciences (NASDAQ: GILD) for instance. </p>
                </section>
                <section>
                    <h2>Influencer Marketing</h2>
                    <p>Influencer marketing involves product placement and endorsement from influencers. Celebrities most of the time, influencers are the people who have the expertise, some kind of authority, or immense knowledge. It was once considered an uncertain enthusiasm escorted by celebrities on the internet, it has grown into a qualified form of digital marketing used by most of the well-known brands and celebrities. As an example, look at how Kylie Jenner launched HER OWN brand and she is HER OWN influencer, she was declared as a self-made billionaire in 2019. </p>
                    <p>
                    Companies are keeping their game on. Finding the right influencer is considered difficult by 61% of companies while the rates of influencer posts have risen beyond all social media platforms. YouTubers charged $400 in 2014 and the cost per endorsement in 2019 is around $6700, while influencers on Instagram cost $134 in 2014 and $1600 in 2019. This is not the extreme point and you can imagine the decrease in print media usage. 63% of the businesses are planning to increase their influencer marketing budget next year. 
                    </p>
                    <p>Hiring a top-end influencer is not cheap these days. Kylie Jenner and like celebrities can make more than $1 million per Instagram post, and Cristiano Ronaldo costs over $975,000 for one sponsored Instagram post, this rate is because of the buzz these celebrities create in the public globally. Let’s think of the revenue such celebrities drive-in. For every $1 spent, companies make $5.20 on average. The top 13% of companies using influencer marketing make more than $20, and only the bottom 25% of companies fail to bring in any revenue using this form of digital marketing. </p>
                    <p>The trend is changing now. Bloggers and YouTube/Instagram celebrities, besides top names in influencers, are ruling this marketing industry. For example, departmental store chains are hiring YouTubers and fashion bloggers can get millions for their videos and posts respectively. As a matter of fact, micro-influencers having 10,000 to 100,000 followers are featuring about 90% of the influencer marketing campaigns. </p>
                    <hr />
                </section>
                <section>
                    <h2>Retargeting</h2>
                    <p>Retargeting or behavioral retargeting, or remarketing, is a tactic of digital marketing that targets audiences based on the history of their internet activity. This maintains a brand’s exposure by retargeting even after a user leaves their website as only 2% of the internet traffic converts from a viewer to an actual customer. </p>
                    <p>The figures show how crucial and effective retargeting can be being in your digital marketing strategy. The CTR increases by 10 times and conversion doubles with retargeting ads, while the CTRs and conversion rates keep dropping naturally when conventional display marketing ads are used. The cost of retargeting with its average CTR and conversion rates is 2 to 100 times lesser as compared to typical advertising revealing a very appealing ROI. </p>
                    <p>A recent study compared six different means of digital marketing and found out that there was an increase of 1046% in retargeting marketing-related search queries. </p>
                    
                </section>

            </section>
            </article>
        </div>
      </div>
    </div>
    <Foot className="style3" title="Conclusion" 
    description="">
    <p>The world, even in these unexpected crushing times, speeds up adapting and evolving, digital marketing advanced with the new technology and is demanded the most. Companies will make their mark and strengthen their growth through investing and planning a far-reaching digital marketing strategy in 2020. The statistical and factual explained earlier mention the opportunities and obstacles of each form and why having a pre-planned and diverse strategy is important. These statistics also show how each tactic is effective when used as a standalone and as a component of a well-round comprehensive digital strategy along with other forms. Businesses can reach huge audience pools through a well-defined extensive digital marketing strategy in a cost-efficient, measurable, and individualized manner. Companies can create brand awareness and can get to know their consumer base finer and more personally. All of these reasons state clearly how prime it is to invest in digital marketing. </p>

    </Foot>
  </Layout>
)

export default Industries

